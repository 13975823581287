/* eslint-disable import/no-anonymous-default-export */
import icon from './TRX-icon.svg'

export default {
  id: 'tron',
  name: 'Tron',
  code: 'TRX',
  symbol: 'TRX',
  website: 'https://tron.network/',
  youtube: '',
  color: '#EF0027',
  icon
}

export const colors = [
  '#00adee',
  '#ff00ad',
  '#ff9922',
  '#34F932',
  '#8989FE',

  '#2ecc71',
  '#03aedd',
  '#2980b9',
  '#aa88dd',
  '#3499ad',

  '#85df94',
  '#FFFF00',
  '#FF0000',
  '#0000FF'
]

/* eslint-disable import/no-anonymous-default-export */
import icon from './USDT-icon.svg'
export default {
  id: 'tether',
  name: 'Tether',
  code: 'USDT',
  symbol: '₮',
  website: '',
  youtube: '',
  color: '#26A17B',
  icon
}

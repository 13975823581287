/* eslint-disable import/no-anonymous-default-export */
import icon from './XLM-icon.svg'

export default {
  id: 'stellar',
  name: 'Stellar',
  code: 'XLM',
  symbol: 'XLM',
  website: '',
  youtube: '',
  color: '#000',
  icon
}
